const $ = require('jquery');
const initDatepicker = require('./datepicker');
const initPayments = require('./payments');
import { preInitBootstrapSelect, initBootstrapSelect } from './bootstrap_select';
import initTurboConfirmations from "./turbo_confirmations";
import * as bootstrap from 'bootstrap';

$(document).on('turbo:load', () => {
  initDatepicker();
  initPayments();
  initBootstrapSelect();

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  if (tooltipTriggerList.length > 0) {
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
      if (!bootstrap.Tooltip.getInstance(tooltipTriggerEl)) {
        new bootstrap.Tooltip(tooltipTriggerEl, {
          trigger: 'hover',
        });
      }
    });
  }

  $('input.upload').on('change', function changeUploadButton() {
    const $button = $(this).closest('div.file-upload-button').find('span');
    if ($button.html().includes('Upload')) $button.html('Uploaded');
    else $button.html('File chosen');
  });

  $('input.upload-instant').on('change', function instantSubmitfunction() {
    $(this).closest('form').submit();
  });

  $('input[type=number]').on('paste', function pasteNumericValue(e) {
    e.preventDefault();
    const pastedValue = e.originalEvent.clipboardData.getData('text/plain');
    let result = pastedValue.replace(/\s/, '');
    if (pastedValue.includes(',') && pastedValue.includes('.')) {
      // 10,000.00
      result = result.replace(/,/g, '');
    } else if (pastedValue.includes(',')) {
      // 10000,00
      result = result.replace(',', '.');
    }
    this.value = result;
  });
});

$(document).on('turbo:before-cache', preInitBootstrapSelect);
initTurboConfirmations();
