// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../src/global_jquery_loader';
import '@hotwired/turbo-rails';

import '@rails/actiontext';
import 'trix';

require('bootstrap');
require('bootstrap-datepicker');

import '../src/index';
