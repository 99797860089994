import { Turbo } from "@hotwired/turbo-rails";
import { Modal } from "bootstrap";

/**
 * Returns classes for footer button variants.
 * Variant names are the same as in ButtonComponent.
 * @param {string} variant - only the variants used in modal windows
 * @returns {Array<string>}
 */
function variantBtnClasses(variant) {
  switch (variant) {
    case "primary":
      return ["btn-primary"];
    case "danger":
      return ["btn-danger"];
    case "cancel":
      return ["btn-light", "border"];
    default:
      return ["btn-primary"];
  }
}

/**
 * Returns an element for the header of the modal confirmation window with the passed text.
 * @param {string} text - header title text
 * @returns {HTMLDivElement}
 */
function modalHeader(text) {
  const element = document.createElement("div");
  const title = document.createElement("h5");
  const closeButton = document.createElement("button");

  element.classList.add("modal-header");

  title.classList.add("modal-title");
  title.textContent = text;

  closeButton.classList.add("btn-close");
  closeButton.setAttribute("type", "button");
  closeButton.setAttribute("aria-label", "Close");
  closeButton.setAttribute("data-bs-dismiss", "modal");

  element.appendChild(title);
  element.appendChild(closeButton);

  return element;
}

/**
 * Returns an element for the body of the modal confirmation window with the passed content.
 * @param {string} content - body content
 * @returns {HTMLDivElement}
 */
function modalBody(content) {
  const element = document.createElement("div");
  const bodyContent = document.createElement("p");

  element.classList.add("modal-body");
  bodyContent.insertAdjacentHTML("afterbegin", content);
  element.appendChild(bodyContent);

  return element;
}

/**
 * Returns an element for the footer of the modal confirmation window with the buttons.
 * @param {object} params - the object with button params
 * @param {string} params.okLabel - the text that will be on the ok button
 * @param {string} params.okVariant - name of the ok button variant (as in the component)
 * @param {string} params.cancelLabel - the text that will be on the cancel button
 * @param {string} params.cancelVariant - name of the cancel button variant (as in the component)
 * @returns {HTMLDivElement}
 */
function modalFooter(
  {
    okLabel = "Yes",
    okVariant = "primary",
    cancelLabel = "Cancel",
    cancelVariant = "cancel",
  },
) {
  const element = document.createElement("div");
  const okBtn = document.createElement("button");
  const cancelBtn = document.createElement("button");
  const defaultBtnClasses = [
    "btn",
    "d-inline-flex",
    "gap-2",
    "align-items-center",
    "text-nowrap",
    "btn-small",
  ];

  element.classList.add("modal-footer");

  cancelBtn.classList.add(
    ...defaultBtnClasses,
    ...variantBtnClasses(cancelVariant),
  );
  cancelBtn.setAttribute("data-bs-dismiss", "modal");
  cancelBtn.setAttribute("type", "button");
  cancelBtn.textContent = cancelLabel;

  element.appendChild(cancelBtn);

  okBtn.classList.add(
    ...defaultBtnClasses,
    ...variantBtnClasses(okVariant),
  );
  okBtn.setAttribute("id", "confirm");
  okBtn.setAttribute("type", "button");
  okBtn.textContent = okLabel;

  element.appendChild(okBtn);

  return element;
}

/**
 * Creates a modal window element.
 * @param {object} params - object with the parameters needed to create a modal
 * @param {string} params.headerText - modal window header title text
 * @param {object} params.data - object with dataset of the pressed element
 * @returns {HTMLDivElement}
 */
function createCustomModal({ headerText, data }) {
  const element = document.createElement("div");
  const wrapperElement = document.createElement("div");
  const innerWrapperElement = document.createElement("div");
  const headerElement = modalHeader(headerText);
  const footerElement = modalFooter(data);

  element.classList.add("modal", "fade");
  wrapperElement.classList.add("modal-dialog");
  innerWrapperElement.classList.add("modal-content");

  element.appendChild(wrapperElement);
  wrapperElement.appendChild(innerWrapperElement);
  innerWrapperElement.appendChild(headerElement);

  if (data.confirmBody) {
    const bodyElement = modalBody(data.confirmBody);

    innerWrapperElement.appendChild(bodyElement);
  } else {
    headerElement.classList.add("border-bottom-0");
  }

  innerWrapperElement.appendChild(footerElement);

  return element;
}

/**
 * A function that handles the display of a modal and the response from a button in it.
 * @param {string} text - modal window header title text. Provided by `data-confirm=text`
 * @param {HTMLFormElement} form - the form to be sent in case of confirmation
 * @param {HTMLButtonElement|undefined} submitter - element that triggered the modal window
 * @returns {Promise<boolean>}
 */
export function handleModalResponse(text, form, submitter) {
  let originalElement = submitter;

  // Natively works only when using a button in a form
  // https://github.com/hotwired/turbo/issues/811
  // This block makes it possible to use turbo_method with the link button
  if (!submitter) {
    const method = form.getAttribute("method");
    const matchingElements = document.querySelectorAll(
      `a[data-turbo-confirm="${text}"][data-turbo-method="${method}"]`,
    );

    originalElement = [...matchingElements].find((el) => form.action.includes(el.href));
  }

  const params = { headerText: text, data: originalElement.dataset };
  const modalElement = createCustomModal(params);
  const bootstrapModal = new Modal(modalElement, { backdrop: "static" });

  bootstrapModal.show();

  return new Promise((resolve) => {
    modalElement.querySelector("#confirm").addEventListener("click", () => {
      resolve(true);
      bootstrapModal.hide();
    });

    modalElement
      .querySelectorAll('[data-bs-dismiss="modal"]')
      .forEach((dismiss) =>
        dismiss.addEventListener(
          "click",
          () => {
            resolve(false);
            bootstrapModal.hide();
          },
        )
      );

    modalElement.addEventListener("hidden.bs.modal", () => {
      bootstrapModal.dispose();
      modalElement.remove();
    });
  });
}

export default function init() {
  Turbo.setConfirmMethod(handleModalResponse);
}
