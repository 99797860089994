// bootstrap-select requires `bootstrap` to be in global namespace to reference `Dropdown`.
import * as bootstrap from 'bootstrap';

import $ from 'jquery';

window.bootstrap = bootstrap;
require('bootstrap-select/js/bootstrap-select');

export function preInitBootstrapSelect() {
  // https://github.com/snapappointments/bootstrap-select/issues/1413#issuecomment-231936277
  $('.select-picker, .datagrid-filter select:not(.selectize-in-grid)')
    .selectpicker('destroy')
    .addClass('selectpicker');
}

export function initBootstrapSelect(selector) {
  const commonSelectPickerOptions = {
    liveSearch: true,
    actionsBox: true,
    style: 'btn-outline-light selectpicker-button',
    deselectAllText: 'None',
    selectAllText: 'All',
  };
  if (selector) {
    $(selector).selectpicker(commonSelectPickerOptions);
    return;
  }

  // Later reloaded on turbo:before-cache event to avoid duplicating itself.
  $('.select-picker').selectpicker(commonSelectPickerOptions);
  $('.datagrid-filter select:not(.selectize-in-grid)').selectpicker({
    ...commonSelectPickerOptions,
    container: '.datagrid-form',
  });
}
