const $ = require('jquery');

module.exports = () => {
  $('#payment_date').on('change', () => {
    if ($('#payment_date').val() === '') {
      $('#payment-form')[0].reportValidity();
    } else {
      $('#payment_refresh_rate').val(1);
      $('#payment-form').submit();
    }
  });

  $('#account_id').on('change', () => {
    $('#payment_refresh_rate').val(1);
    $('#payment-form').submit();
  });

  // UI changes when changing transfer/reception pair payments.
  function changeOpenPairButton($button, pairId) {
    if (pairId.length === 0) $button.hide();
    else {
      $button.show();
      const href = $button.attr('href');
      const currentId = parseInt(href.slice('/payments/'.length), 10);
      $button.attr('href', href.replace(currentId, pairId));
    }
  }

  let oldPaymentType = $('[data-id="payment_type"]').val();
  const $transferPairSelect = $('#payment_pair_id_transfer');
  const $receptionPairSelect = $('#payment_pair_id_reception');
  const $transferOpenButton = $('.transfer-specific .open-pair');
  const $receptionOpenButton = $('.reception-specific .open-pair');
  $transferPairSelect.on('change', function changePairButton() {
    changeOpenPairButton($transferOpenButton, $(this).val());
  });
  $receptionPairSelect.on('change', function changePairButton() {
    changeOpenPairButton($receptionOpenButton, $(this).val());
  });

  // Buttons to set VAT percentage.
  const $currentCategoryRate = $('#current-category-rate');
  const categoryIdsToRates = new Map(
    Array.from($('#category-vat-rates li')).map(elem => $(elem).text().trim().split('-'))
  );
  const $addVatButton = $('#add-vat-amount');
  const $splitVatButton = $('#split-vat-amount');

  function currentVatPercent(value) {
    if (value) return $currentCategoryRate.text(value);
    return parseInt($currentCategoryRate.text().trim(), 10);
  }
  function changeVatPercent() {
    const $paymentType = $('#payment-form :input[name="payment[type]"]');
    const $categoryInput = $(`#payment_${$paymentType.val()}_category_id`);
    const newVatPercent = categoryIdsToRates.get($categoryInput.val());
    if (newVatPercent === undefined) {
      $addVatButton.hide();
      $splitVatButton.hide();
    } else {
      $addVatButton.show();
      $splitVatButton.show();
      currentVatPercent(newVatPercent);
      $addVatButton.text(`Add ${newVatPercent}%`);
      $splitVatButton.text(`Split ${newVatPercent}%`);
    }
  }

  const addVatTooltipEl = document.querySelector('[data-bs-toggle="tooltip-add-vat"]');
  if (addVatTooltipEl) {
    new bootstrap.Tooltip(addVatTooltipEl, {
      title: () => `Add ${currentVatPercent()}% VAT on top of Amount`,
      container: 'body',
    });
  };

  const splitVatTooltipEl = document.querySelector('[data-bs-toggle="tooltip-split-vat"]');
  if (splitVatTooltipEl) {
    new bootstrap.Tooltip(splitVatTooltipEl, {
      title: () => `Split ${currentVatPercent()}% VAT out of Amount`,
      container: 'body',
    });
  };

  changeVatPercent();
  $('#payment_income_category_id, #payment_expense_category_id').on('change', changeVatPercent);

  $('#add-vat-amount').on('click', () => {
    const vatPercent = currentVatPercent();
    const amount = parseFloat($('#payment_amount').val(), 10);
    const vat = Math.ceil(amount * vatPercent) / 100;
    $('#payment_vat_amount').val(vat);
  });
  $('#split-vat-amount').on('click', () => {
    const vatPercent = currentVatPercent();
    const amount = parseFloat($('#payment_amount').val(), 10);
    const splittedAmount = Math.floor((amount / (1 + vatPercent / 100)) * 100) / 100;
    const vat = Math.round((amount - splittedAmount) * 100) / 100;
    $('#payment_vat_amount').val(vat);
    $('#payment_amount').val(splittedAmount);
  });

  $('#payment-form').on('submit', () => {
    $('input, select').prop('disabled', false);
  });

  // Display only necessary form elements for different payment types.
  function changePaymentType(obj) {
    if (obj.length === 0) {
      $('.income-or-expense-specific').hide();
      $('.transfer-or-reception-specific').hide();
      return;
    }

    const paymentType = $(obj).val();

    if (
      ((paymentType === 'income' || paymentType === 'reception') &&
        (oldPaymentType === 'expense' || oldPaymentType === 'transfer')) ||
      ((oldPaymentType === 'income' || oldPaymentType === 'reception') &&
        (paymentType === 'expense' || paymentType === 'transfer'))
    ) {
      const paymentAmount = document.getElementById('payment_amount').value || 0.0;
      const paymentVatAmount = document.getElementById('payment_vat_amount').value || 0.0;
      document.getElementById('payment_amount').value = -paymentAmount;
      document.getElementById('payment_vat_amount').value = -paymentVatAmount;
    }
    if (paymentType === 'income' || paymentType === 'expense') {
      $('.income-or-expense-specific').show();
      $('.transfer-or-reception-specific').hide();
      changeVatPercent();
      if (paymentType === 'income') {
        $('.income-specific').show();
        $('.expense-specific').hide();
      } else if (paymentType === 'expense') {
        $('.income-specific').hide();
        $('.expense-specific').show();
      }
    } else if (paymentType === 'transfer' || paymentType === 'reception') {
      $('.income-or-expense-specific').hide();
      $('.transfer-or-reception-specific').show();
      if (paymentType === 'transfer') {
        $('.transfer-specific').show();
        $('.reception-specific').hide();
        changeOpenPairButton($transferOpenButton, $transferPairSelect.val());
      } else if (paymentType === 'reception') {
        $('.transfer-specific').hide();
        $('.reception-specific').show();
        changeOpenPairButton($receptionOpenButton, $receptionPairSelect.val());
      }
    }
    oldPaymentType = paymentType;
  }

  changePaymentType($('#payment-form :input[name="payment[type]"]'));
  $('#payment-form :input[name="payment[type]"]').change(function changeType() {
    changePaymentType(this);
  });

  // Switch sliders for existing or new records.
  ['#counterparty-switch', '#case-switch', '#employee-switch'].forEach(switchId => {
    $(switchId).on('change', function showInputs() {
      const existingInputId = `#${this.id}-existing-input`;
      const newInputId = `#${this.id}-new-input`;
      if (this.checked) {
        $(existingInputId).addClass('d-none');
        $(newInputId).removeClass('d-none');
      } else {
        $(existingInputId).removeClass('d-none');
        $(newInputId).addClass('d-none');
      }
    });
  });
};
